import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Logo extends Component {
  render() {
    let { logoStyle } = this.props;
    console.log(logoStyle);
    return (
        <Link className="navbar-brand logo_h" activeClass="active" to="/">
         {
            (logoStyle == 'dark-theme')

            ? <>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="171.643" height="31.384" viewBox="0 0 171.643 31.384">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_14" data-name="Rectangle 14" width="171.643" height="31.384" fill="#fff"/>
                    </clipPath>
                  </defs>
                  <g id="Reyco_logo_wh" data-name="Reyco logo wh" clip-path="url(#clip-path)">
                    <path id="Path_20" data-name="Path 20" d="M6.277,10.909V23.364H0V.164C.05.119.123,0,.193,0c4.563.121,10.763.1,13.1.1,5.555.292,5.784,6.949,3.8,8.77a7.969,7.969,0,0,1-3.788,1.915c3.452.423,4.71,3.047,5.851,5.872.938,2.32,2.067,4.563,3.1,6.806-4.156,1.186-7.625-.589-9.074-4.847-.532-1.564-.984-3.166-1.655-4.668a4.846,4.846,0,0,0-5.249-3.033m-.025-.515c1,0,1.777.021,2.551,0A2.539,2.539,0,0,0,11.29,8.5,9.349,9.349,0,0,0,11.2,2.207C10.615.638,9.8.06,6.863.388,6.6.475,6.281.661,6.278.943c-.041,3.1-.026,6.2-.026,9.452" transform="translate(0 0)" fill="#fff"/>
                    <path id="Path_21" data-name="Path 21" d="M536.869,23.593a8.165,8.165,0,0,1-9.957-5.566,27.717,27.717,0,0,0-1.207-3.735,4.781,4.781,0,0,0-2.006-2.12c-1.83-.854-2.647-.206-2.652,1.784q-.01,4.6-.032,9.2c0,.085-.071.169-.141.324h-6.1V.119h6.247v11.4L530.807.221l.487.18s-7.883,9.106-9.149,10.56c1.371-.2,2.542-.467,3.723-.53a6.346,6.346,0,0,1,6.008,3.173,37.116,37.116,0,0,1,1.735,3.361c1.089,2.2,2.165,4.4,3.258,6.628" transform="translate(-406.629 -0.094)" fill="#fff"/>
                    <path id="Path_22" data-name="Path 22" d="M642.527,44.32c-.943.811-1.751,1.581-2.637,2.248a6.275,6.275,0,0,1-4.969,1.191,4.019,4.019,0,0,1-3.629-4.212c-.015-4.331,0-8.661,0-13.112h6.021c.025.47.076.981.077,1.492.007,3.848,0,7.7.008,11.544a7.425,7.425,0,0,0,.116,1.6c.273,1.219,1.123,1.693,2.157,1.043,1.656-1.042,3.112-2.2,2.991-4.574-.161-3.158-.04-6.33-.04-9.5V30.4h6.1c0,2.3,0,4.528,0,6.754,0,2.241-.021,4.482.022,6.721a6.449,6.449,0,0,0,.384,1.479l.4-.026.595-1.071a3.556,3.556,0,0,1-3.245,3.561c-2.237.245-3.657-.87-4.344-3.495" transform="translate(-498.658 -24.01)" fill="#fff"/>
                    <path id="Path_23" data-name="Path 23" d="M736.932,34.4c-.824,1.751-2.516,2.4-4.03,1.54a3.11,3.11,0,0,1-.7-4.5,6.373,6.373,0,0,1,5.21-2.535,17.079,17.079,0,0,1,5.7.831,6.255,6.255,0,0,1,4.361,6.413c0,2.143-.019,4.287.018,6.429a8.371,8.371,0,0,0,.319,1.555l.411.038.674-1.1a3.626,3.626,0,0,1-2.845,3.51c-2.1.46-3.429-.339-4.793-2.91-1.549,1.718-3.269,3.092-5.749,2.995-2.318-.091-4.209-1.369-4.606-3.137-.466-2.079.412-3.843,2.709-4.942,2.146-1.026,4.368-1.9,6.589-2.762a1.537,1.537,0,0,0,1.182-1.852,11.264,11.264,0,0,1-.013-1.313c-.045-1.9-.7-3.044-2.033-3.313-1.241-.251-2.462-.193-3.258,1.019-.779,1.186-.449,2.659.852,4.032m4.441,1.191c-.989.665-1.724,1.107-2.4,1.624a5.333,5.333,0,0,0-2.324,4.894,2.123,2.123,0,0,0,2.2,2.205,2.319,2.319,0,0,0,2.521-2.358c.013-1.987,0-3.974,0-6.365" transform="translate(-577.252 -22.83)" fill="#fff"/>
                    <path id="Path_24" data-name="Path 24" d="M125.667,34.782H114.927c.033,2.713.51,5.089,2.326,6.982,2.1,2.188,5.4,1.84,7.353-.735.513-.676.9-1.447,1.44-2.325a7.671,7.671,0,0,1-5.748,6.911,8.98,8.98,0,0,1-9.612-3.348,8.719,8.719,0,0,1-.82-9.471,9.6,9.6,0,0,1,11.842-4.2c2.608,1.072,3.943,3.1,3.96,6.187m-5.321-.41a34.962,34.962,0,0,0-.892-4.611c-.571-1.718-2.128-1.87-3.2-.4a7.9,7.9,0,0,0-1.337,5.01Z" transform="translate(-85.975 -22.059)" fill="#fff"/>
                    <path id="Path_25" data-name="Path 25" d="M289.059,33.549c1.137-.894,1.74-2.033,1.152-3.335a3.555,3.555,0,0,0-1.769-1.679,2.485,2.485,0,0,0-3.27,1.683,11.955,11.955,0,0,0,.911,10.8c1.849,2.943,5.7,2.966,7.931.218a24.05,24.05,0,0,0,1.5-2.339c.17,2.731-2.455,5.968-5.6,6.735a8.846,8.846,0,0,1-9.622-3.277,8.242,8.242,0,0,1-.955-9.31,9.312,9.312,0,0,1,12.359-4.122,6.606,6.606,0,0,1,2.208,1.827,2.973,2.973,0,0,1-.6,4.269c-1.4.908-3.189.338-4.243-1.465" transform="translate(-219.852 -22.094)" fill="#fff"/>
                    <path id="Path_26" data-name="Path 26" d="M182.539,29.485c2.052,0,3.846-.065,5.629.049.414.026.938.59,1.159,1.033,1.538,3.084,3.01,6.2,4.618,9.548l4.912-10.682.894-.089s-6.879,14.1-10.047,20.769a11.064,11.064,0,0,1-2.362,3.274c-2.679,2.3-7.351,1.05-8.023-2.2a3.366,3.366,0,0,1,2.709-3.875,3.1,3.1,0,0,1,3.781,2.532,1.416,1.416,0,0,1-.541,1.109c-.841.593-1.308,1.314-.653,2.19.712.952,2.422-.1,3.02-.932a22.8,22.8,0,0,0,2.923-5.262,1.759,1.759,0,0,0-.033-1.43c-2.4-4.83-4.834-9.645-7.254-14.466-.214-.427-.4-.866-.732-1.574" transform="translate(-141.597 -23.178)" fill="#fff"/>
                    <path id="Path_27" data-name="Path 27" d="M381.111,28.966a8.951,8.951,0,1,0,9.349,8.961,9.069,9.069,0,0,0-9.349-8.961m.1,17.568c-1.6.122-3.936-1.632-3.947-8.624.011-7.333,2.35-8.5,3.947-8.624,1.034-.17,3.958,1.292,3.947,8.624.3,6.993-2.328,8.747-3.947,8.624" transform="translate(-293.817 -22.88)" fill="#fff"/>
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="171.643" height="31.384" viewBox="0 0 171.643 31.384">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_14" data-name="Rectangle 14" width="171.643" height="31.384" fill="none"/>
                    </clipPath>
                  </defs>
                  <g id="Reyco_logo-blk" data-name="Reyco logo-blk" clip-path="url(#clip-path)">
                    <path id="Path_20" data-name="Path 20" d="M6.277,10.909V23.364H0V.164C.05.119.123,0,.193,0c4.563.121,10.763.1,13.1.1,5.555.292,5.784,6.949,3.8,8.77a7.969,7.969,0,0,1-3.788,1.915c3.452.423,4.71,3.047,5.851,5.872.938,2.32,2.067,4.563,3.1,6.806-4.156,1.186-7.625-.589-9.074-4.847-.532-1.564-.984-3.166-1.655-4.668a4.846,4.846,0,0,0-5.249-3.033m-.025-.515c1,0,1.777.021,2.551,0A2.539,2.539,0,0,0,11.29,8.5,9.349,9.349,0,0,0,11.2,2.207C10.615.638,9.8.06,6.863.388,6.6.475,6.281.661,6.278.943c-.041,3.1-.026,6.2-.026,9.452" transform="translate(0 0)"/>
                    <path id="Path_21" data-name="Path 21" d="M536.869,23.593a8.165,8.165,0,0,1-9.957-5.566,27.717,27.717,0,0,0-1.207-3.735,4.781,4.781,0,0,0-2.006-2.12c-1.83-.854-2.647-.206-2.652,1.784q-.01,4.6-.032,9.2c0,.085-.071.169-.141.324h-6.1V.119h6.247v11.4L530.807.221l.487.18s-7.883,9.106-9.149,10.56c1.371-.2,2.542-.467,3.723-.53a6.346,6.346,0,0,1,6.008,3.173,37.116,37.116,0,0,1,1.735,3.361c1.089,2.2,2.165,4.4,3.258,6.628" transform="translate(-406.629 -0.094)"/>
                    <path id="Path_22" data-name="Path 22" d="M642.527,44.32c-.943.811-1.751,1.581-2.637,2.248a6.275,6.275,0,0,1-4.969,1.191,4.019,4.019,0,0,1-3.629-4.212c-.015-4.331,0-8.661,0-13.112h6.021c.025.47.076.981.077,1.492.007,3.848,0,7.7.008,11.544a7.425,7.425,0,0,0,.116,1.6c.273,1.219,1.123,1.693,2.157,1.043,1.656-1.042,3.112-2.2,2.991-4.574-.161-3.158-.04-6.33-.04-9.5V30.4h6.1c0,2.3,0,4.528,0,6.754,0,2.241-.021,4.482.022,6.721a6.449,6.449,0,0,0,.384,1.479l.4-.026.595-1.071a3.556,3.556,0,0,1-3.245,3.561c-2.237.245-3.657-.87-4.344-3.495" transform="translate(-498.658 -24.01)"/>
                    <path id="Path_23" data-name="Path 23" d="M736.932,34.4c-.824,1.751-2.516,2.4-4.03,1.54a3.11,3.11,0,0,1-.7-4.5,6.373,6.373,0,0,1,5.21-2.535,17.079,17.079,0,0,1,5.7.831,6.255,6.255,0,0,1,4.361,6.413c0,2.143-.019,4.287.018,6.429a8.371,8.371,0,0,0,.319,1.555l.411.038.674-1.1a3.626,3.626,0,0,1-2.845,3.51c-2.1.46-3.429-.339-4.793-2.91-1.549,1.718-3.269,3.092-5.749,2.995-2.318-.091-4.209-1.369-4.606-3.137-.466-2.079.412-3.843,2.709-4.942,2.146-1.026,4.368-1.9,6.589-2.762a1.537,1.537,0,0,0,1.182-1.852,11.264,11.264,0,0,1-.013-1.313c-.045-1.9-.7-3.044-2.033-3.313-1.241-.251-2.462-.193-3.258,1.019-.779,1.186-.449,2.659.852,4.032m4.441,1.191c-.989.665-1.724,1.107-2.4,1.624a5.333,5.333,0,0,0-2.324,4.894,2.123,2.123,0,0,0,2.2,2.205,2.319,2.319,0,0,0,2.521-2.358c.013-1.987,0-3.974,0-6.365" transform="translate(-577.252 -22.83)"/>
                    <path id="Path_24" data-name="Path 24" d="M125.667,34.782H114.927c.033,2.713.51,5.089,2.326,6.982,2.1,2.188,5.4,1.84,7.353-.735.513-.676.9-1.447,1.44-2.325a7.671,7.671,0,0,1-5.748,6.911,8.98,8.98,0,0,1-9.612-3.348,8.719,8.719,0,0,1-.82-9.471,9.6,9.6,0,0,1,11.842-4.2c2.608,1.072,3.943,3.1,3.96,6.187m-5.321-.41a34.962,34.962,0,0,0-.892-4.611c-.571-1.718-2.128-1.87-3.2-.4a7.9,7.9,0,0,0-1.337,5.01Z" transform="translate(-85.975 -22.059)"/>
                    <path id="Path_25" data-name="Path 25" d="M289.059,33.549c1.137-.894,1.74-2.033,1.152-3.335a3.555,3.555,0,0,0-1.769-1.679,2.485,2.485,0,0,0-3.27,1.683,11.955,11.955,0,0,0,.911,10.8c1.849,2.943,5.7,2.966,7.931.218a24.05,24.05,0,0,0,1.5-2.339c.17,2.731-2.455,5.968-5.6,6.735a8.846,8.846,0,0,1-9.622-3.277,8.242,8.242,0,0,1-.955-9.31,9.312,9.312,0,0,1,12.359-4.122,6.606,6.606,0,0,1,2.208,1.827,2.973,2.973,0,0,1-.6,4.269c-1.4.908-3.189.338-4.243-1.465" transform="translate(-219.852 -22.094)"/>
                    <path id="Path_26" data-name="Path 26" d="M182.539,29.485c2.052,0,3.846-.065,5.629.049.414.026.938.59,1.159,1.033,1.538,3.084,3.01,6.2,4.618,9.548l4.912-10.682.894-.089s-6.879,14.1-10.047,20.769a11.064,11.064,0,0,1-2.362,3.274c-2.679,2.3-7.351,1.05-8.023-2.2a3.366,3.366,0,0,1,2.709-3.875,3.1,3.1,0,0,1,3.781,2.532,1.416,1.416,0,0,1-.541,1.109c-.841.593-1.308,1.314-.653,2.19.712.952,2.422-.1,3.02-.932a22.8,22.8,0,0,0,2.923-5.262,1.759,1.759,0,0,0-.033-1.43c-2.4-4.83-4.834-9.645-7.254-14.466-.214-.427-.4-.866-.732-1.574" transform="translate(-141.597 -23.178)"/>
                    <path id="Path_27" data-name="Path 27" d="M381.111,28.966a8.951,8.951,0,1,0,9.349,8.961,9.069,9.069,0,0,0-9.349-8.961m.1,17.568c-1.6.122-3.936-1.632-3.947-8.624.011-7.333,2.35-8.5,3.947-8.624,1.034-.17,3.958,1.292,3.947,8.624.3,6.993-2.328,8.747-3.947,8.624" transform="translate(-293.817 -22.88)"/>
                  </g>
                </svg>
              </>

            :

              <>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="171.643" height="31.384" viewBox="0 0 171.643 31.384">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_14" data-name="Rectangle 14" width="171.643" height="31.384" fill="#fff"/>
                    </clipPath>
                  </defs>
                  <g id="Reyco_logo_wh" data-name="Reyco logo wh" clip-path="url(#clip-path)">
                    <path id="Path_20" data-name="Path 20" d="M6.277,10.909V23.364H0V.164C.05.119.123,0,.193,0c4.563.121,10.763.1,13.1.1,5.555.292,5.784,6.949,3.8,8.77a7.969,7.969,0,0,1-3.788,1.915c3.452.423,4.71,3.047,5.851,5.872.938,2.32,2.067,4.563,3.1,6.806-4.156,1.186-7.625-.589-9.074-4.847-.532-1.564-.984-3.166-1.655-4.668a4.846,4.846,0,0,0-5.249-3.033m-.025-.515c1,0,1.777.021,2.551,0A2.539,2.539,0,0,0,11.29,8.5,9.349,9.349,0,0,0,11.2,2.207C10.615.638,9.8.06,6.863.388,6.6.475,6.281.661,6.278.943c-.041,3.1-.026,6.2-.026,9.452" transform="translate(0 0)" fill="#fff"/>
                    <path id="Path_21" data-name="Path 21" d="M536.869,23.593a8.165,8.165,0,0,1-9.957-5.566,27.717,27.717,0,0,0-1.207-3.735,4.781,4.781,0,0,0-2.006-2.12c-1.83-.854-2.647-.206-2.652,1.784q-.01,4.6-.032,9.2c0,.085-.071.169-.141.324h-6.1V.119h6.247v11.4L530.807.221l.487.18s-7.883,9.106-9.149,10.56c1.371-.2,2.542-.467,3.723-.53a6.346,6.346,0,0,1,6.008,3.173,37.116,37.116,0,0,1,1.735,3.361c1.089,2.2,2.165,4.4,3.258,6.628" transform="translate(-406.629 -0.094)" fill="#fff"/>
                    <path id="Path_22" data-name="Path 22" d="M642.527,44.32c-.943.811-1.751,1.581-2.637,2.248a6.275,6.275,0,0,1-4.969,1.191,4.019,4.019,0,0,1-3.629-4.212c-.015-4.331,0-8.661,0-13.112h6.021c.025.47.076.981.077,1.492.007,3.848,0,7.7.008,11.544a7.425,7.425,0,0,0,.116,1.6c.273,1.219,1.123,1.693,2.157,1.043,1.656-1.042,3.112-2.2,2.991-4.574-.161-3.158-.04-6.33-.04-9.5V30.4h6.1c0,2.3,0,4.528,0,6.754,0,2.241-.021,4.482.022,6.721a6.449,6.449,0,0,0,.384,1.479l.4-.026.595-1.071a3.556,3.556,0,0,1-3.245,3.561c-2.237.245-3.657-.87-4.344-3.495" transform="translate(-498.658 -24.01)" fill="#fff"/>
                    <path id="Path_23" data-name="Path 23" d="M736.932,34.4c-.824,1.751-2.516,2.4-4.03,1.54a3.11,3.11,0,0,1-.7-4.5,6.373,6.373,0,0,1,5.21-2.535,17.079,17.079,0,0,1,5.7.831,6.255,6.255,0,0,1,4.361,6.413c0,2.143-.019,4.287.018,6.429a8.371,8.371,0,0,0,.319,1.555l.411.038.674-1.1a3.626,3.626,0,0,1-2.845,3.51c-2.1.46-3.429-.339-4.793-2.91-1.549,1.718-3.269,3.092-5.749,2.995-2.318-.091-4.209-1.369-4.606-3.137-.466-2.079.412-3.843,2.709-4.942,2.146-1.026,4.368-1.9,6.589-2.762a1.537,1.537,0,0,0,1.182-1.852,11.264,11.264,0,0,1-.013-1.313c-.045-1.9-.7-3.044-2.033-3.313-1.241-.251-2.462-.193-3.258,1.019-.779,1.186-.449,2.659.852,4.032m4.441,1.191c-.989.665-1.724,1.107-2.4,1.624a5.333,5.333,0,0,0-2.324,4.894,2.123,2.123,0,0,0,2.2,2.205,2.319,2.319,0,0,0,2.521-2.358c.013-1.987,0-3.974,0-6.365" transform="translate(-577.252 -22.83)" fill="#fff"/>
                    <path id="Path_24" data-name="Path 24" d="M125.667,34.782H114.927c.033,2.713.51,5.089,2.326,6.982,2.1,2.188,5.4,1.84,7.353-.735.513-.676.9-1.447,1.44-2.325a7.671,7.671,0,0,1-5.748,6.911,8.98,8.98,0,0,1-9.612-3.348,8.719,8.719,0,0,1-.82-9.471,9.6,9.6,0,0,1,11.842-4.2c2.608,1.072,3.943,3.1,3.96,6.187m-5.321-.41a34.962,34.962,0,0,0-.892-4.611c-.571-1.718-2.128-1.87-3.2-.4a7.9,7.9,0,0,0-1.337,5.01Z" transform="translate(-85.975 -22.059)" fill="#fff"/>
                    <path id="Path_25" data-name="Path 25" d="M289.059,33.549c1.137-.894,1.74-2.033,1.152-3.335a3.555,3.555,0,0,0-1.769-1.679,2.485,2.485,0,0,0-3.27,1.683,11.955,11.955,0,0,0,.911,10.8c1.849,2.943,5.7,2.966,7.931.218a24.05,24.05,0,0,0,1.5-2.339c.17,2.731-2.455,5.968-5.6,6.735a8.846,8.846,0,0,1-9.622-3.277,8.242,8.242,0,0,1-.955-9.31,9.312,9.312,0,0,1,12.359-4.122,6.606,6.606,0,0,1,2.208,1.827,2.973,2.973,0,0,1-.6,4.269c-1.4.908-3.189.338-4.243-1.465" transform="translate(-219.852 -22.094)" fill="#fff"/>
                    <path id="Path_26" data-name="Path 26" d="M182.539,29.485c2.052,0,3.846-.065,5.629.049.414.026.938.59,1.159,1.033,1.538,3.084,3.01,6.2,4.618,9.548l4.912-10.682.894-.089s-6.879,14.1-10.047,20.769a11.064,11.064,0,0,1-2.362,3.274c-2.679,2.3-7.351,1.05-8.023-2.2a3.366,3.366,0,0,1,2.709-3.875,3.1,3.1,0,0,1,3.781,2.532,1.416,1.416,0,0,1-.541,1.109c-.841.593-1.308,1.314-.653,2.19.712.952,2.422-.1,3.02-.932a22.8,22.8,0,0,0,2.923-5.262,1.759,1.759,0,0,0-.033-1.43c-2.4-4.83-4.834-9.645-7.254-14.466-.214-.427-.4-.866-.732-1.574" transform="translate(-141.597 -23.178)" fill="#fff"/>
                    <path id="Path_27" data-name="Path 27" d="M381.111,28.966a8.951,8.951,0,1,0,9.349,8.961,9.069,9.069,0,0,0-9.349-8.961m.1,17.568c-1.6.122-3.936-1.632-3.947-8.624.011-7.333,2.35-8.5,3.947-8.624,1.034-.17,3.958,1.292,3.947,8.624.3,6.993-2.328,8.747-3.947,8.624" transform="translate(-293.817 -22.88)" fill="#fff"/>
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="171.643" height="31.384" viewBox="0 0 171.643 31.384">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_14" data-name="Rectangle 14" width="171.643" height="31.384" fill="none"/>
                    </clipPath>
                  </defs>
                  <g id="Reyco_logo-blk" data-name="Reyco logo-blk" clip-path="url(#clip-path)">
                    <path id="Path_20" data-name="Path 20" d="M6.277,10.909V23.364H0V.164C.05.119.123,0,.193,0c4.563.121,10.763.1,13.1.1,5.555.292,5.784,6.949,3.8,8.77a7.969,7.969,0,0,1-3.788,1.915c3.452.423,4.71,3.047,5.851,5.872.938,2.32,2.067,4.563,3.1,6.806-4.156,1.186-7.625-.589-9.074-4.847-.532-1.564-.984-3.166-1.655-4.668a4.846,4.846,0,0,0-5.249-3.033m-.025-.515c1,0,1.777.021,2.551,0A2.539,2.539,0,0,0,11.29,8.5,9.349,9.349,0,0,0,11.2,2.207C10.615.638,9.8.06,6.863.388,6.6.475,6.281.661,6.278.943c-.041,3.1-.026,6.2-.026,9.452" transform="translate(0 0)"/>
                    <path id="Path_21" data-name="Path 21" d="M536.869,23.593a8.165,8.165,0,0,1-9.957-5.566,27.717,27.717,0,0,0-1.207-3.735,4.781,4.781,0,0,0-2.006-2.12c-1.83-.854-2.647-.206-2.652,1.784q-.01,4.6-.032,9.2c0,.085-.071.169-.141.324h-6.1V.119h6.247v11.4L530.807.221l.487.18s-7.883,9.106-9.149,10.56c1.371-.2,2.542-.467,3.723-.53a6.346,6.346,0,0,1,6.008,3.173,37.116,37.116,0,0,1,1.735,3.361c1.089,2.2,2.165,4.4,3.258,6.628" transform="translate(-406.629 -0.094)"/>
                    <path id="Path_22" data-name="Path 22" d="M642.527,44.32c-.943.811-1.751,1.581-2.637,2.248a6.275,6.275,0,0,1-4.969,1.191,4.019,4.019,0,0,1-3.629-4.212c-.015-4.331,0-8.661,0-13.112h6.021c.025.47.076.981.077,1.492.007,3.848,0,7.7.008,11.544a7.425,7.425,0,0,0,.116,1.6c.273,1.219,1.123,1.693,2.157,1.043,1.656-1.042,3.112-2.2,2.991-4.574-.161-3.158-.04-6.33-.04-9.5V30.4h6.1c0,2.3,0,4.528,0,6.754,0,2.241-.021,4.482.022,6.721a6.449,6.449,0,0,0,.384,1.479l.4-.026.595-1.071a3.556,3.556,0,0,1-3.245,3.561c-2.237.245-3.657-.87-4.344-3.495" transform="translate(-498.658 -24.01)"/>
                    <path id="Path_23" data-name="Path 23" d="M736.932,34.4c-.824,1.751-2.516,2.4-4.03,1.54a3.11,3.11,0,0,1-.7-4.5,6.373,6.373,0,0,1,5.21-2.535,17.079,17.079,0,0,1,5.7.831,6.255,6.255,0,0,1,4.361,6.413c0,2.143-.019,4.287.018,6.429a8.371,8.371,0,0,0,.319,1.555l.411.038.674-1.1a3.626,3.626,0,0,1-2.845,3.51c-2.1.46-3.429-.339-4.793-2.91-1.549,1.718-3.269,3.092-5.749,2.995-2.318-.091-4.209-1.369-4.606-3.137-.466-2.079.412-3.843,2.709-4.942,2.146-1.026,4.368-1.9,6.589-2.762a1.537,1.537,0,0,0,1.182-1.852,11.264,11.264,0,0,1-.013-1.313c-.045-1.9-.7-3.044-2.033-3.313-1.241-.251-2.462-.193-3.258,1.019-.779,1.186-.449,2.659.852,4.032m4.441,1.191c-.989.665-1.724,1.107-2.4,1.624a5.333,5.333,0,0,0-2.324,4.894,2.123,2.123,0,0,0,2.2,2.205,2.319,2.319,0,0,0,2.521-2.358c.013-1.987,0-3.974,0-6.365" transform="translate(-577.252 -22.83)"/>
                    <path id="Path_24" data-name="Path 24" d="M125.667,34.782H114.927c.033,2.713.51,5.089,2.326,6.982,2.1,2.188,5.4,1.84,7.353-.735.513-.676.9-1.447,1.44-2.325a7.671,7.671,0,0,1-5.748,6.911,8.98,8.98,0,0,1-9.612-3.348,8.719,8.719,0,0,1-.82-9.471,9.6,9.6,0,0,1,11.842-4.2c2.608,1.072,3.943,3.1,3.96,6.187m-5.321-.41a34.962,34.962,0,0,0-.892-4.611c-.571-1.718-2.128-1.87-3.2-.4a7.9,7.9,0,0,0-1.337,5.01Z" transform="translate(-85.975 -22.059)"/>
                    <path id="Path_25" data-name="Path 25" d="M289.059,33.549c1.137-.894,1.74-2.033,1.152-3.335a3.555,3.555,0,0,0-1.769-1.679,2.485,2.485,0,0,0-3.27,1.683,11.955,11.955,0,0,0,.911,10.8c1.849,2.943,5.7,2.966,7.931.218a24.05,24.05,0,0,0,1.5-2.339c.17,2.731-2.455,5.968-5.6,6.735a8.846,8.846,0,0,1-9.622-3.277,8.242,8.242,0,0,1-.955-9.31,9.312,9.312,0,0,1,12.359-4.122,6.606,6.606,0,0,1,2.208,1.827,2.973,2.973,0,0,1-.6,4.269c-1.4.908-3.189.338-4.243-1.465" transform="translate(-219.852 -22.094)"/>
                    <path id="Path_26" data-name="Path 26" d="M182.539,29.485c2.052,0,3.846-.065,5.629.049.414.026.938.59,1.159,1.033,1.538,3.084,3.01,6.2,4.618,9.548l4.912-10.682.894-.089s-6.879,14.1-10.047,20.769a11.064,11.064,0,0,1-2.362,3.274c-2.679,2.3-7.351,1.05-8.023-2.2a3.366,3.366,0,0,1,2.709-3.875,3.1,3.1,0,0,1,3.781,2.532,1.416,1.416,0,0,1-.541,1.109c-.841.593-1.308,1.314-.653,2.19.712.952,2.422-.1,3.02-.932a22.8,22.8,0,0,0,2.923-5.262,1.759,1.759,0,0,0-.033-1.43c-2.4-4.83-4.834-9.645-7.254-14.466-.214-.427-.4-.866-.732-1.574" transform="translate(-141.597 -23.178)"/>
                    <path id="Path_27" data-name="Path 27" d="M381.111,28.966a8.951,8.951,0,1,0,9.349,8.961,9.069,9.069,0,0,0-9.349-8.961m.1,17.568c-1.6.122-3.936-1.632-3.947-8.624.011-7.333,2.35-8.5,3.947-8.624,1.034-.17,3.958,1.292,3.947,8.624.3,6.993-2.328,8.747-3.947,8.624" transform="translate(-293.817 -22.88)"/>
                  </g>
                </svg>

              </>
           }
        </Link>
    );
  }
}
export default Logo;
