import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import reycoData from '../component/reycoData';

function About(props) {
 // let reycoData = props.reycoData;

  return (
    <section className="" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 d-flex align-items-center">
            <Fade direction="up" triggerOnce="true" damping={0.1}>
              <div className="about_content">
                <h2 className="t_color">{reycoData.aboutme}</h2>
                <h6>{reycoData.role}</h6>
                <p>{reycoData.aboutdetails}</p>
                <Link to="/" className="theme_btn active">
                  Hire Me
                </Link>
                <Link to="/" className="theme_btn">
                  Download CV
                </Link>
              </div>
            </Fade>
          </div>
          <div className="col-lg-5">
            <div className="about_img">
              <Fade direction="right" duration={1500}>
                <img src={require("../image/apporoach_man_img.png")} alt="" />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
