import { createGlobalStyle } from "styled-components";
import * as theme from "./Theme.styled";

export const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-family: monospace;
  overflow-x: hidden;
}

section {
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
}

.t_color {
    color: ${({ theme }) => theme.colors.subtitle};
}

.portfolio_area .arrow-button {
    color: ${({ theme }) => theme.colors.subtitle};
    border-bottom: 3px solid ${({ theme }) => theme.colors.subtitle};
}

.portfolio_area .arrow-button > .arrow {
  border-right: 2px solid ${({ theme }) => theme.colors.subtitle};
  border-bottom: 2px solid ${({ theme }) => theme.colors.subtitle};
}

.slick-prev:before, .slick-next:before {
    color: ${({ theme }) => theme.colors.consbg};
}



// theme buttons color
.light {
  background-color: ${theme.light.colors.header};
}
.dark {
  background-color: ${theme.dark.colors.header};
}


`;
