import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";

import imageUrl from "../../image/banner_img.svg";

class frelencerBanner extends Component {
  render() {
    return (
      <section className="frelencer_banner_area" id="home">
        <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_two"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_five"></div>
          <div className="bubble b_six"></div>
        </div>
        <Fade className="s_shap" direction="right" duration={500} triggerOnce>
          <img src={imageUrl} alt="svg" />
        </Fade>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="frelencer_content">
                <Fade direction="up" duration={500} triggerOnce>
                  <h2 className="t_color">
                    Hey there!
                    <br /> I'm Martin Solonick Independent{" "}
                    <span>Frelencer</span>
                  </h2>
                </Fade>
                <Fade direction="up" duration={700} triggerOnce>
                  <h4>
                    <span>I create thousand off website</span>
                  </h4>
                </Fade>
                <Fade direction="up" duration={900} triggerOnce>
                  <a href="./" className="theme_btn active">
                    Hire me
                  </a>
                </Fade>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="frelencer_img">
                <Fade direction="left" duration={1400} triggerOnce>
                  <img
                    className="f_img_one"
                    src={require("../../image/f_man.png")}
                    alt=""
                  />
                </Fade>
                <Fade direction="fight" duration={1200} triggerOnce>
                  <img
                    className="f_img_two"
                    src={require("../../image/table.png")}
                    alt=""
                  />
                </Fade>
                <Fade direction="down" duration={1600} triggerOnce>
                  <img
                    className="f_img_three"
                    src={require("../../image/cup.png")}
                    alt=""
                  />
                </Fade>
                <Fade direction="down" duration={1600} triggerOnce>
                  <img
                    className="f_img_four"
                    src={require("../../image/shadow.png")}
                    alt=""
                  />
                </Fade>
                <Fade effect="down" duration={2000} triggerOnce>
                  <div className="f_img_five">
                    <img
                      className=""
                      src={require("../../image/php.png")}
                      alt=""
                    />
                  </div>
                </Fade>
                <div className="f_img_six">
                  <img
                    className=""
                    src={require("../../image/c++.png")}
                    alt=""
                  />
                </div>
                <div className="f_img_seven">
                  <img
                    className=""
                    src={require("../../image/search.png")}
                    alt=""
                  />
                </div>
                <div className="f_img_eight">
                  <img
                    className=""
                    src={require("../../image/arrow.png")}
                    alt=""
                  />
                </div>
                <div className="f_img_nine">
                  <img
                    className=""
                    src={require("../../image/brakets.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default frelencerBanner;
