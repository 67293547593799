import React, { Component } from "react";

class SocialIcon extends Component {

  render() {
    let { wrapperclass } = this.props;
    return (
        <ul class={`social-list ${wrapperclass}`}>
            <li class="list">
                <a href="https://www.facebook.com/reyco.kua/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                      <path id="ico_blk_-_fb" data-name="ico blk - fb" d="M52.563,26.721A26,26,0,1,0,22.5,52.563V34.282H15.9V26.721h6.6V20.957c0-6.555,3.879-10.176,9.82-10.176a39.779,39.779,0,0,1,5.821.511v6.434H34.862c-3.229,0-4.237,2.017-4.237,4.085v4.91h7.211l-1.153,7.562H30.625v18.28A26.12,26.12,0,0,0,52.563,26.721Z" transform="translate(-0.563 -0.563)"/>
                    </svg>
                </a>
            </li>
            <li class="list">
                <a href="https://www.instagram.com/reyco1006/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                      <g id="ico_blk_-_insta" data-name="ico blk - insta" transform="translate(-158 -2961)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="26" cy="26" r="26" transform="translate(158 2961)"/>
                        <path id="Icon_simple-instagram" data-name="Icon simple-instagram" d="M18,0c-4.89,0-5.5.023-7.42.108A13.273,13.273,0,0,0,6.21.945,8.815,8.815,0,0,0,3.021,3.021,8.783,8.783,0,0,0,.945,6.21a13.234,13.234,0,0,0-.837,4.37C.018,12.5,0,13.11,0,18s.023,5.5.108,7.42a13.281,13.281,0,0,0,.837,4.37,8.827,8.827,0,0,0,2.076,3.189A8.8,8.8,0,0,0,6.21,35.055a13.289,13.289,0,0,0,4.37.837c1.92.09,2.53.108,7.42.108s5.5-.022,7.42-.108a13.32,13.32,0,0,0,4.37-.837,9.2,9.2,0,0,0,5.265-5.265,13.281,13.281,0,0,0,.837-4.369C35.982,23.5,36,22.89,36,18s-.022-5.5-.108-7.42a13.312,13.312,0,0,0-.837-4.37,8.834,8.834,0,0,0-2.076-3.189A8.77,8.77,0,0,0,29.79.945,13.242,13.242,0,0,0,25.421.108C23.5.018,22.89,0,18,0Zm0,3.24c4.8,0,5.378.024,7.275.106a9.917,9.917,0,0,1,3.34.622,5.923,5.923,0,0,1,3.417,3.415,9.938,9.938,0,0,1,.619,3.34c.085,1.9.1,2.469.1,7.275s-.022,5.378-.111,7.275a10.13,10.13,0,0,1-.632,3.34,5.715,5.715,0,0,1-1.348,2.073,5.616,5.616,0,0,1-2.07,1.344,10.011,10.011,0,0,1-3.353.619c-1.911.085-2.473.1-7.288.1s-5.379-.022-7.288-.111a10.207,10.207,0,0,1-3.354-.632,5.574,5.574,0,0,1-2.069-1.348,5.466,5.466,0,0,1-1.35-2.07,10.215,10.215,0,0,1-.63-3.353c-.068-1.89-.092-2.473-.092-7.266s.024-5.379.092-7.292a10.2,10.2,0,0,1,.63-3.351,5.336,5.336,0,0,1,1.35-2.071A5.324,5.324,0,0,1,7.313,3.917a9.963,9.963,0,0,1,3.332-.632c1.913-.068,2.475-.09,7.288-.09L18,3.24Zm0,5.517A9.243,9.243,0,1,0,27.243,18,9.242,9.242,0,0,0,18,8.757ZM18,24a6,6,0,1,1,6-6A6,6,0,0,1,18,24ZM29.769,8.392a2.16,2.16,0,1,1-2.16-2.158A2.162,2.162,0,0,1,29.769,8.392Z" transform="translate(166 2969)" fill="#fff"/>
                      </g>
                    </svg>
                </a>
            </li>
            <li class="list">
                <a href="https://www.linkedin.com/in/reyco-kua/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                      <g id="ico_blk_-_linkedin" data-name="ico blk - linkedin" transform="translate(-222 -2961)">
                        <circle id="Ellipse_18" data-name="Ellipse 18" cx="26" cy="26" r="26" transform="translate(222 2961)"/>
                        <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M7.051,31.5H.52V10.47H7.051ZM3.782,7.6A3.8,3.8,0,1,1,7.564,3.783,3.814,3.814,0,0,1,3.782,7.6ZM31.493,31.5H24.976V21.263c0-2.44-.049-5.569-3.4-5.569-3.4,0-3.916,2.651-3.916,5.393V31.5H11.142V10.47h6.263v2.869H17.5a6.862,6.862,0,0,1,6.179-3.4c6.609,0,7.824,4.352,7.824,10.005V31.5Z" transform="translate(232 2970.999)" fill="#fff"/>
                      </g>
                    </svg>
                </a>
            </li>
            <li class="list">
                <a href="mailto:reyco_kua@hotmail.com">
                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                      <g id="ico_blk_-_email" data-name="ico blk - email" transform="translate(-285 -2963)">
                        <circle id="Ellipse_17" data-name="Ellipse 17" cx="26" cy="26" r="26" transform="translate(285 2963)"/>
                        <path id="Icon_zocial-email" data-name="Icon zocial-email" d="M.072,25.063V6.108q0-.033.1-.625l11.155,9.543L.2,25.721a2.789,2.789,0,0,1-.132-.658Zm1.481-20.9a1.418,1.418,0,0,1,.559-.1H32.156a1.863,1.863,0,0,1,.592.1L21.56,13.743l-1.481,1.185-2.929,2.4-2.929-2.4-1.481-1.185ZM1.586,27,12.807,16.244l4.344,3.521,4.344-3.521L32.716,27a1.58,1.58,0,0,1-.559.1H2.112a1.49,1.49,0,0,1-.527-.1Zm21.39-11.978L34.1,5.483a1.964,1.964,0,0,1,.1.625V25.063a2.522,2.522,0,0,1-.1.658Z" transform="translate(293.866 2973.415)" fill="#fff"/>
                      </g>
                    </svg>
                </a>
            </li>
        </ul>
    );
  }
}
export default SocialIcon;
