import React, { Component } from "react";
import SectionTitleTwo from "../../component/Banner/SectionTitleTwo";
import TestimonialSliderTwo from "./TestimonialSliderTwo";
import testimonial1 from "../../image/testimonial.svg";

class TestimonialTwo extends Component {
  render() {
    return (
      <div className="testimonial_area_two" id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="testimonial_img">
                <img src={testimonial1} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonial_slider_info">
                <SectionTitleTwo
                  stitle="Customer feedback"
                  btitle="What our customers say about John.."
                />
                <TestimonialSliderTwo />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialTwo;
