import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Fade } from "react-awesome-reveal";
import Typer from "./Typer";

class TypeBanner extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section id="home">
        <Parallax
          bgImage={require("../../image/intro-bg.jpg")}
          strength={0}
          className="banner_area"
        >
          <div className="container">
            <div className="banner_content">
              <Fade direction="up">
                <h5 className="wow fadeInUp animated">HELLO</h5>
              </Fade>
              <Fade direction="up" duration={1500}>
                <h2 className="wow fadeInLeft animated">{jhonData.name2}</h2>
              </Fade>
              <Fade direction="up" duration={2000}>
                {" "}
                <Typer
                  heading={"I am a"}
                  dataText={[
                    "Web Developer",
                    "Photographer",
                    "UI/UX Designer",
                    "Freelancer",
                  ]}
                />
              </Fade>
              <ul className="list_style social_icon">
                {jhonData.socialLinks &&
                  jhonData.socialLinks.map((item) => {
                    return (
                      <li key={item.name}>
                        <a href={item.url}>
                          <i className={item.className}></i>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

export default TypeBanner;
