import React, { useState, useEffect } from "react";
import { GlobalStyles } from "./component/Styles/Global";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { light, dark } from "./component/Styles/Theme.styled";
import Navbar from './component/Navbar/Navbar';
/*-----pages-----*/
//import Home from "./Home";
//import AboutPage from "./About";
import Home from "./component/Home";
import About from "./component/About";
import reycoData from './component/reycoData';
import Contact from './component/Contact';
import Footer from './component/Footer';



import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home5 from "./Home5";
import Home6 from "./Home6";
import Homefrelencer from "./Home-frelencer";
import HomeDeveloper from "./Home-developer";
import NotFound from "./404";

function App() {
  // theme state
  const [selectedTheme, setSelectedTheme] = useState(light);

  // react hook to get the theme selected by the user that is saved in local storage
  useEffect(() => {
    const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
    if (currentTheme) {
      setSelectedTheme(currentTheme);
    }
  }, []);

  // function to handle user theme selection on click and save it to local storage
  const HandleThemeChange = (theme) => {
    console.log(theme);
    setSelectedTheme(theme);
    localStorage.setItem("current-theme", JSON.stringify(theme));
  };

  return (
    <React.Fragment>
        <ThemeProvider theme={selectedTheme}>
            <GlobalStyles />
            <BrowserRouter>
              <div className="body_wrapper">
                <Navbar mContainer="custome_container" mainlogo="logo2.png" stickylogo="logo.png" selectedTheme={selectedTheme} HandleThemeChange={HandleThemeChange} reycoData={reycoData} />
                  <Routes>
                    <Route exact path='/' element={<Home selectedTheme={selectedTheme} HandleThemeChange={HandleThemeChange} />} />
                  </Routes>
              </div>
            </BrowserRouter>
        </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
