import React, {Component} from 'react';
import { Fade } from "react-awesome-reveal";
import WaterWave from 'react-water-wave';
import { Link } from "react-router-dom";
import CV from '../../Resume.pdf';

class Banner extends Component {
    render(){
        let reycoData = this.props.reycoData;
        let {bClass, textLeft, imag}= this.props;
        return(
            <section id="home" >
                 <WaterWave interactive={0} className={`banner_area ${bClass}`} style={{ width: '100%', height: '100%', backgroundSize: 'cover' }} imageUrl={require ("../../image/" + imag)}>
                        {({ getRootProps}) => (
                             <div className="container">
                                <div className={`banner_content ${textLeft}`}>
                                    <Fade direction="up">
                                        <h5>REYCO KUA</h5>
                                    </Fade>
                                    <Fade direction="up" duration={1500}>
                                        <h2 className="wow fadeInLeft animated">Passionate Creative Designer</h2>
                                    </Fade>
                                    <br />
                                    Welcome to my creativity and innovation page! The site is undergoing an exciting transformation to bring you a design experience like never before. While I'm busy crafting my digital masterpiece, stay tuned for a sneak peek into my page of passionate design.
                                    <div className="banner_contact">
                                      <a href={CV}  className="theme_btn active" target="_blank">Download CV</a>
                                      <br /><br />
                                      <ul className="list_style social_icon">
                                        {
                                            reycoData.socialLinks && reycoData.socialLinks.map(item =>{
                                            return(
                                                    <li key={item.name}>
                                                    <a  href={item.url}><i className={item.className}></i></a>
                                                    </li>
                                                    )
                                                }
                                            )
                                        }
                                      </ul>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        )}
                </WaterWave>
            </section>
        );
    }
}

export default Banner;