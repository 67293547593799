const jhonData = {
  name: "Reyco Kua.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Passionate Creative Designer",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/reyco.kua/",
      className: "social_facebook",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/reyco1006/",
      className: "social_instagram",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/reyco-kua/",
      className: "social_linkedin",
    },
  ],
  aboutme: "About Me",
  aboutdetails:
    "I am Jhone Deo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever It has survived not only five centuries, but also the leap into electronic but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  hcontact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_phone",
          text: "+64 210572336",
          class: "first",
          link: "tel:64210572336",
        },
        {
          id: 2,
          icon: "icon_mail",
          text: "Email: reyco_kua@hotmail.com",
          class: "",
          link: "mailto:reyco_kua@hotmail.com",
        },
      ],
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Z105 - Tan Thinh, Thai Nguyen - Viet Nam",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +1.900.3456.789",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: yourname@somemail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: http://example.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
  copyrighttext: "&copy; 2024 Reyco Kua - All Rights Reserved",
};

export default jhonData;
