import React, { Component, useState, useEffect } from "react";
import Sticky from "react-stickynode";
import { Link } from 'react-router-dom';
import ThemeSwitcher from '../../component/Svg/ThemeSwitcher.js';
import ReactLogo from '../../component/Svg/Logo.js';
import SocialIcon from '../../component/Svg/SocialIcon.js';
import reycoData from '../../component/reycoData';

class Navbar extends Component {
  render() {
    var { mClass, mContainer, mainlogo, stickylogo, selectedTheme, HandleThemeChange } = this.props;
    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
          <div className={`container ${mContainer}`}>
            <ReactLogo logoStyle={selectedTheme.name} />
          </div>
        </nav>
      </Sticky>
    );
  }
}

export default Navbar;
