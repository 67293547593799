import React, {Component} from 'react';
import ItemGrid from './ItemGrid';
import Sectiontitle from '../../component/Banner/Sectiontitle';

class portfolio extends Component {
    render() {
      var {pClass, triggerFrom}= this.props;
      return(
        <section className={`portfolio_area ${pClass}`} id="portfolio">
            <div className="container">
                <Sectiontitle Title="My latest work" />
                <ItemGrid pClass={pClass} />
                <div className="">
                    <button className="arrow-button">
                        Check more work<span className="arrow"></span>
                    </button>
                </div>
            </div>
        </section>
      )
    }
  }

  export default portfolio;
