import React from 'react';
import Navbar from '../component/Navbar/Navbar';
import RBanner from '../component/Banner/RippleBanner';

import Service from '../component/Service';
import Portfolio from '../component/Portfolio/Portfolio';
import Testimonial from '../component/Testimonial/Testimonial';
import Clientslogo from '../component/Clientslogo';
import Blog from '../component/Blog/Blog';
import Contact from '../component/Contact';
import Skill from '../component/Skill/Skill';

import reycoData from '../component/reycoData';

function Home(props) {
  return (
    <div>
      <RBanner reycoData={reycoData} imag="Homepage.png"/>
    </div>
  );

}


export default Home;
