import React, { Component, useState, useEffect } from "react";
import { light, dark } from "../../component/Styles/Theme.styled";

class ThemeSwitcher extends Component {
    changeTheme = (theme) => {
        this.props.HandleThemeChange(theme);
    }

    render() {
        var { selectedTheme, pClass } = this.props;

        return (
            <div>
            {
               (selectedTheme.name == 'dark-theme') ?
                <>
                    <button className={`${pClass}`} onClick={() => this.changeTheme(light)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="39.138" viewBox="0 0 38 39.138">
                          <g id="Sun_Mode" data-name="Sun Mode" transform="translate(0 1.138)">
                            <g id="Group_45" data-name="Group 45">
                              <g id="Group_46" data-name="Group 46">
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="19" cy="19" r="19" fill="#dfdfdf"/>
                                <g id="Group_43" data-name="Group 43" transform="translate(-312.038 -17.176)">
                                  <circle id="Ellipse_2-2" data-name="Ellipse 2" cx="19" cy="19" r="19" transform="translate(312.038 16.038)" fill="rgba(255,255,255,0.3)"/>
                                  <path id="Icon_material-wb-sunny" data-name="Icon material-wb-sunny" d="M7.675,5.424,5.745,3.505,4.234,5.017,6.153,6.935ZM4.716,11.491H1.5v2.144H4.716ZM14.364.825H12.22V3.987h2.144V.825Zm7.986,4.192L20.839,3.505,18.92,5.424l1.512,1.512,1.919-1.919ZM18.909,19.7l1.919,1.93,1.512-1.512L20.41,18.2l-1.5,1.5Zm2.959-8.212v2.144h3.216V11.491Zm-8.576-5.36a6.432,6.432,0,1,0,6.432,6.432A6.437,6.437,0,0,0,13.292,6.131ZM12.22,24.3h2.144V21.14H12.22ZM4.234,20.11l1.512,1.512,1.919-1.93L6.153,18.181,4.234,20.11Z" transform="translate(317.647 23.322)"/>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                    </button>
                </>

                :

                <>
                    <button className={`${pClass}`} onClick={() => this.changeTheme(dark)}>
                        <svg id="Night_Mode" data-name="Night Mode" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
                          <g id="Group_46" data-name="Group 46">
                            <circle id="Ellipse_2" data-name="Ellipse 2" cx="19" cy="19" r="19" fill="#dfdfdf"/>
                            <path id="Icon_awesome-moon" data-name="Icon awesome-moon" d="M12.973,22.12a11.039,11.039,0,0,0,8.591-4.1.519.519,0,0,0-.5-.836A8.664,8.664,0,0,1,15.156,1.145a.519.519,0,0,0-.162-.96A11.06,11.06,0,1,0,12.973,22.12Z" transform="translate(7.087 8.487)"/>
                          </g>
                        </svg>
                    </button>
                </>
           }
           </div>

        );
    }
}
export default ThemeSwitcher;
